<template>
    <div class="LetsConnect Wrapper-section" id="LetsConnectPage">
        <div class="Trigger TriggerTop" id="LetsConnectTriggerTop"></div>
        <div class="LetsConnect-container">
            <figure class="LetsConnectIllustration">
                <img :src="`${this.publicPath}img/smartFox.jpg`" alt="" />
            </figure>
            <div class="LetsConnectIntro" id="LetsConnect">
                <h2 class="title LetsConnectIntro-title">Let’s get to know one another.</h2>
                <a :href="`mailto:${headquarter.email}`" class="Button LetsConnectCta">Say hello</a>
            </div>
        </div>
        <div class="Trigger TriggerBottom" id="LetsConnectTriggerBottom"></div>
    </div>
</template>

<script>
    import store from '@/store';

    export default {
        name: 'LetsConnect',
        props: {
            label: {
                type: String,
                default: 'Let’s Connect!'
            }
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                offices: store.state.offices,
                sceneTimer: null,
                scrollMagicTrigger: ['LetsConnectTriggerTop'],
                scrollMagicSectionId: ['LetsConnectPage']
            };
        },
        computed: {
            headquarter() {
                return this.offices.filter(office => office.headquarter)[0];
            }
        },
        mounted() {
            this.$nextTick(this.prepareScene);
        },
        methods: {
            prepareScene() {
                this.sceneTimer = setInterval(this.createScene, 1000);
            },
            createScene() {
                clearInterval(this.sceneTimer);
                const SMC = new this.$scrollmagic.Controller();

                for (let i = 0; i < this.scrollMagicSectionId.length; i++) {
                    const scene = new this.$scrollmagic.Scene({
                        triggerElement: `#${this.scrollMagicTrigger[i]}`,
                        triggerHook: 'onEnter'
                    }).setClassToggle(`#${this.scrollMagicSectionId[i]}`, 'visible'); // add class to reveal
                    SMC.addScene(scene);
                }
            }
        }
    };
</script>
